<template>
  <div>
    <iframe :src="resumeUrl" width="100%" height="600"></iframe>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
const route = useRoute();
const store = useStore();
const resumeUrl = ref('');
const getResumeUrl = async () => {
  try {
    const url = await store.getters['global/getResumeUrl'](route.params.uri);
    resumeUrl.value = `${url}`;
  } catch (error) {}
};
onMounted(async () => {
  await getResumeUrl();
});
</script>
